.popup {
  visibility: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0;
  z-index: 1;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  transition: background-color 0.3s;
}

.popup_active {
  visibility: visible;
  background-color: rgba(1, 1, 1, 0.7);
}

.popup__form {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  position: absolute;
  top: 200%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  transition: top 0.3s;
  background: #1e1e1eba;
  max-width: 700px;
  min-height: 60%;
  width: 90%;
  border-radius: 15px;
}

.popup__form_active {
  visibility: visible;
  top: 50%;
}

.popup__btn {
  width: 115px;
  height: 30px;
  border-radius: 20px;
  border: none;
  background-color: rgb(60 60 60);
  color: #8b8b8b;
  transition: var(--standart-transition);
  font-size: 15px;
}

.popup__btn:first-of-type {
  margin-right: 5px;
}

.popup__btn:hover {
  cursor: pointer;
  opacity: 0.7;
}

.popup__input {
  border: none;
  border-bottom: 1px solid #8B8B8B;
  padding-bottom: 5px;
  margin-top: 7px;
  background-color: #00000000;
  font-size: 17px;
  color: #FFF;
}

.popup__label {
  display: flex;
  flex-direction: column;
  line-height: 12px;
  color: #8B8B8B;
  min-width: 60%;
  font-size: 15px;
  position: relative;
  margin-bottom: 35px;
}

.popup__container {
  display: flex;
  margin: 35px 0 25px 55px;
  align-items: baseline;
  flex-direction: column;
}

.popup__img {
  width: 20px;
  position: absolute;
  left: -28px;
  top: -5px;
}

.popup__textarea {
  background: #3c3c3c;
  border: none;
  border-radius: 5px;
  resize: none;
  margin-top: 12px;
  color: #fff;
  min-height: 70px;
  padding: 4px;
}

.popup__label_text {
  min-width: 80%;
}

.popup__info-container {
  display: flex;
  gap: 10px;
}

.popup__input-upload {
  display: none;
}

.popup__label-upload {
  margin-top: 12px;
  padding: 8px 10px;
  display: flex;
  align-self: baseline;
  background: #3c3c3c;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  opacity: 1;
  transition: var(--standart-transition);
}

.popup__file-name {
  position: absolute;
  left: 145px;
  top: 31px;
}

.popup__label-upload:hover {
  opacity: 0.7;
}

.btn-cross {
  border: none;
}

.btn-cross:hover {
  background: #3c3c3c;
  cursor: pointer;
}

.btn-cross svg {
  display: block;
}

.btn-cross svg line {
  stroke: #8B8B8B;
  stroke-width: 5;
}

.popup__close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 8px;
  border-radius: 50%;
  background: none;
  transition: var(--standart-transition);
}

.popup__close-btn svg {
  width: 18px;
}

.popup__btn-container {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

@media (max-width: 580px) {
  .popup__container {
    margin: 35px 0 25px 32px;
  }

  .popup__close-btn {
    top: 7px;
    right: 7px;
  }

  .popup__label {
    min-width: 85%;
  }

  .popup__form_active {
    width: 100%;
  }
}




