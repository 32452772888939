.popup {
  visibility: hidden;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 1, 1, 0.4);
  position: fixed;
  top: 0px;
  left: 0px;
  margin: 0;
  z-index: 1;
  transition: background-color 0.3s;
}

.popup_active {
  visibility: visible;
  background-color: rgba(1, 1, 1, 0.7);
}

.popup__del-form {
  visibility: hidden;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  position: absolute;
  top: 200%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  transition: top 0.3s;
}

.popup__del-form_active {
  visibility: visible;
  top: 50%;
}

.popup__del-btn {
  width: 255px;
  height: 50px;
  border-radius: 20px;
  border: none;
  background-color: rgb(149 149 149 / 90%);
  color: #fff;
  transition: all 0.3s;
}

.popup__del-btn:first-of-type {
  margin-bottom: 5px;
}

.popup__del-btn_cancel {
  color: #000;
}

.popup__del-btn:hover {
  cursor: pointer;
  background-color: rgb(149 149 149);
}
