.task-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
}

.task-list__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: var(--content-width);
  max-width: 700px;
  border-bottom: 1px solid var(--header-font-color);
}

.task-list__container {
  display: flex;
  align-items: center;
  width: 45%;
  cursor: pointer;
  z-index: 1;
}

.task-list__title {
  font-weight: normal;
  font-size: 17px;
  cursor: pointer;
}

.task-list__title_complete {
  color: rgb(1 1 1 / 50%);
}

.task-list__btn {
  height: 18px;
  width: 18px;
  padding: 0;
  border-radius: 10px;
  border: 1px solid;
  background: transparent;
  margin-right: 7px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.task-list__complete-logo {
  width: 80%;
  opacity: 0;
  transition: var(--standart-transition);
}

.task-list__complete-logo_active {
  opacity: 1;
}

.task-list__complete-logo:hover {
  opacity: 1;
}

.task-list__btn:hover {

}

.task-list__term {
  background: #c2c2c2;
  border-radius: 7px;
  padding: 3px;
  font-size: 14px;
  border: none;
  width: 105px;
  outline: none;
  box-sizing: border-box;
  transition: var(--standart-transition);
}

.task-list__term:hover {
  opacity: 0.8;
}

.task-list__status {
  background: #c2c2c2;
  border-radius: 7px;
  padding: 3px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  width: 105px;
  box-sizing: border-box;
  transition: var(--standart-transition);
}

.task-list__status:hover {
  cursor: pointer;
  opacity: 0.8;
}

.task-list__status option {
  background: #fff;
}

.task-list__status_pending {
  color: #665b1a;
  background: #dfc83f;
}

.task-list__status_complete {
  color: #0d3b14;
  background: #4ab853;
}

.task-list__tools {
  display: flex;
  gap: 7px;
}

.task-list__tools img {
  width: 22px;
  cursor: pointer;
  transition: var(--standart-transition);
  opacity: 0.5;
}

.task-list__tools img:hover {
  opacity: 1;
}

@media (max-width: 780px) {
  .task-list__title {
    font-size: 14px;
  }

  .task-list__term {
    font-size: 13px;
    width: 95px;
  }

  .task-list__status {
    font-size: 13px;
    width: 100px;
  }

  .task-list__tools img {
    width: 20px;
  }
}

@media (max-width: 580px) {
  .task-list__status-list {
    display: none;
  }

  .task-list__tools img {
    display: none;
  }

  .task-list__container {
    width: 75%;
    margin-right: 7px;
  }

}
